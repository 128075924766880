.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}

.button {
  border: none;
  background-color: blueviolet;
  color: white;
  font-weight: bold;
  border-radius: 8px;
}

.button:disabled {
  background-color: gray;
  color: black;
}