.main_div {
  margin: 1rem;
}

.message {
  border: 2px solid #646cff;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  width: 98%;
  font-weight: bold;
}

h5 {
  margin-top: 1rem;
}

.btns {
  margin: 0.5rem 0;
}

.btn {
  border: none;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-bottom: 3rem;
  padding: 0.7rem 1rem;
  color: white;
  font-weight: bold;
}

.del_btn {
  background-color: rgb(237, 61, 61);
}

.view_btn {
  background-color: green;
}

.editor {
  height: 100%;
  min-width: 200px;
}

.editorInput {
  height: 90%;
  width: 100%;
}

code {
  background-color: aliceblue;
  color: black;
}

.ql-clipboard {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.editorjs {
  background-color: rgb(25, 25, 25);
  height: 500px;
  overflow: scroll;
  padding: 0;
}