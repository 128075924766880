* {
  margin: 0;
}

.main_container {
  margin-top: 1rem;
}

.card_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1rem 1rem 1rem 0;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 250px;
  box-shadow: 1px 1px 2px black;
}

.btns {
  margin: 0.5rem 0;
}

.btn {
  border: none;
  border-radius: 5px;
  margin-right: 0.5rem;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
}

.del_btn {
  background-color: rgb(237, 61, 61);
}

.view_btn {
  background-color: #646cff;
}

.metadesc {
  font-size: small;
}
