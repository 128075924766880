.main_div {
  margin: 1rem;
}

.message {
  border: 2px solid #646cff;
  color: white;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  width: 98%;
}

h5 {
  margin-top: 1rem;
}

input,
textarea {
  border-radius: 5px;
  border: none;
  padding: 10px;
  width: 98%;
}

.btns {
  margin: 0.5rem 0;
}

.btn {
  border: none;
  border-radius: 5px;
  margin-right: 0.5rem;
  margin-bottom: 3rem;
}

.del_btn {
  background-color: rgb(237, 61, 61);
}

.view_btn {
  background-color: green;
}

.editor {
  height: 100%;
  min-width: 200px;
}

.editorInput {
  height: 90%;
  width: 100%;
}

code {
  background-color: aliceblue;
  color: black;
}

.img_prvw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.mainImage_preview {
  width: 80px;
  height: 80px;
}

.mainImage_preview img {
  width: inherit;
  height: inherit;
}

.mainImage_preview:hover {
  background-color: red;
}

.inputWrapper {
  height: 80px;
  width: 150px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /*Using a background color, but you can use a background image to represent a button*/
  /* background-color: #ddf; */
  border: 1px solid black;
  background-image: url("/public/selectimages.png");
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  /*This makes the button huge. If you want a bigger button, increase the font size*/
  font-size: 50px;
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}