.navbar {
  width: 100%;
  margin: 1rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.brand {
  font-weight: bold;
  font-size: larger;
}

.navItems {
  margin-right: 5rem;
  list-style: none;
  text-align: center;
}

.navItems li {
  display: inline-block;
  margin-right: 3rem;
}

.navItems li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  font-weight: bold;
}